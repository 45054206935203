@import 'src/styles/colors';

.spinner,
.spinner::after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.spinner {
  font-size: inherit;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid $green;
  transform: translateZ(0);
  animation: spinn 1.1s infinite linear;
}

.black {
  border-left-color: $black;
}

.white {
  border-left-color: $white;
}

.center {
  width: 100%;
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spinn {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

