@import 'src/styles/colors_v2';

.container {
  position: relative;
  width: 100%;
}

.textarea {
  width: 100%;
  border-radius: 8px;
  border: 0;
  background: $smoky-white3;
  resize: none;
  padding: 25px 14px;
  font-size: 14px;
  box-sizing: border-box;

  &.lgPadding {
    padding: 36px 14px;
  }

  &:focus {
    outline: none;
  }
}

.errorActive {
  top: -6px;
  left: 8px;
  font-size: 13px;
  transform: none;
}

.error {
  border-color: $red;
  color: $red;
}

.inputLabelText {
  position: absolute;
  top: 19px;
  left: 13px;
  font-size: 14px;
  color: $telegray;

  transition:
    font-size 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  pointer-events: none;
}

.filledInputLabelText {
  font-size: 12px;
  transform: translateY(-11px);
}
