@import 'src/styles/colors';
@import 'src/styles/colors_v2';

.container {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  padding: 25px 12px 10px;
  background: $smoky-white3;
  border: 1px solid $smoky-white3;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  appearance: textfield;

  &:-autofill,
  &:-autofill:hover,
  &:-autofill:focus,
  &:-autofill:active {
    -box-shadow: 0 0 0 30px $white inset !important;
  }

  &::placeholder {
    font-size: 14px;
    color: $telegray;
    opacity: 1;
  }
}

.inputSearch {
  max-height: 44px;
  box-sizing: border-box;
  padding: 12px 16px;
  background-color: $smoky-white3;
  border: none;
}

.numberImput {
  background: $green-light;
  border-radius: 0;
  height: 100%;
  z-index: 1;
  padding: 0;
  text-align: center;
  border: 0;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
}

.errorActive {
  top: -6px;
  left: 8px;
  font-size: 13px;
  transform: none;
}

.error {
  border-color: $red;
  color: $red;
}

.price {
  box-sizing: border-box;
  padding-left: 25px;
}

.symbol {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: inherit;
}

.closeButton {
  padding: 0;
  position: absolute;
  top: 10px;
  right: 15px;
}

.inputLabelText {
  position: absolute;
  top: 19px;
  left: 13px;
  font-size: 14px;
  color: $telegray;
  transition:
    font-size 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  pointer-events: none;
}

.filledInputLabelText {
  font-size: 12px;
  transform: translateY(-11px);
}

.inputLabel {
  max-height: 52px;
  padding: 16px 12px;
  font-size: 14px;
  border-radius: 12px;
}

.inputLabelWithText {
  padding: 20px 12px 7px 12px;
}

.inputLabelError {
  border: 1px solid $red;
}

.whiteBack {
  background-color: $white;
}
