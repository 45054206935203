@import 'src/styles/colors';
@import 'src/styles/colors_v2';

.container {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  padding: 25px 12px 10px;
  background: $smoky-white3;
  border: 1px solid $smoky-white3;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  font-size: 15px;
  line-height: 15px;
}

.error {
  border-color: $red;
  color: $red;
}

.inputLabelText {
  position: absolute;
  top: 19px;
  left: 13px;
  font-size: 14px;
  color: $telegray;
  transition:
    font-size 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  pointer-events: none;
}

.filledInputLabelText {
  font-size: 12px;
  transform: translateY(-11px);
}
