@import 'src/styles/colors';
@import 'src/styles/breakpoints';
@import '../../styles/colors_v2';
@import 'src/styles/z_index';

.desktopItemsBlock {
  display: flex;
  width: 100%;
  height: 100%;

  .headerCatalogDesktop {
    display: flex;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    max-height: 48px;
    padding: 0 12px;
    max-width: 121px;

    span {
      margin: 0 16px 0 4px;
    }

    img {
      width: 100%;
      height: 100%;
      min-width: 24px;
      min-height: 24px;
    }
  }

  .headerDateDeliveryDesktop {
    margin-right: 10px;
    max-height: 48px;
    width: 100%;
    border-radius: 12px;
    justify-content: space-between;
    padding: 11px 16px;

    div {
      padding: 0 20px;
    }

    img {
      margin-left: 16px;
    }

    @media (min-width: $sm) {
      max-width: 195px;
    }
  }

  .headerSearchIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 12px;
    height: 50px;

    img {
      flex-shrink: 0;
      padding: 0 9px;
    }
  }

  @media (min-width: $sm) {
    margin-left: 16px;
  }
}

.mobileItemsBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  @media (min-width: $sm) {
    display: block;
    width: 160px;
    margin-bottom: 0;
  }
}

.isStickySearch {
  background-color: rgba(255, 255, 255, 0);
}

.popup {
  background-color: $black;
  width: 100%;
  height: 10000vh;
  opacity: 0.3;
  position: absolute;
  overflow: hidden;
  z-index: $popup-menu;
}

.inputSearchPageMobile {
  margin-top: 10px;

  @media (min-width: $sm) {
    display: none;
  }
}

.headerWrap {
  white-space: nowrap;
  font-weight: 400;

  &:global(.isSticky) {
    padding-top: 110px;

    .headerInfoBlock {
      z-index: $z-100;
      position: fixed;
      top: 0;
      background-color: $white;
      padding: 15px 16px;
      transform: translate(-50%, 0);
      left: 50%;
      max-width: 1192px;
      z-index: $header-sticky;
    }

    .stickyBackground {
      display: none;

      @media (min-width: $sm) {
        display: block;
        position: fixed;
        width: 100%;
        height: 73px;
        top: 0;
        left: 0;

        background-color: white;
        z-index: $header-sticky;
      }
    }

    @media (min-width: $sm) {
      padding-top: 50px;
    }
  }
}

.headerLocation {
  background-color: $white-transparent;
  background-size: cover;

  @media (min-width: $sm) {
    padding: 0 12px;
  }
}

.headerLocationFlexContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.locationIcon {
  padding-right: 12px;

  img {
    width: 16px;
    height: 20px;
  }
}

.locationInformation {
  width: 100%;
  line-height: 18px;
  padding: 10px 0;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $black;
}

.addressArea {
  font-weight: 500;

  span {
    color: $white-aluminum;
  }
}

.addressStreet {
  font-weight: 400;
}

.headerBasket {
  display: flex;
  justify-content: space-between;

  @media (min-width: ($sm + 1)) {
    display: none;
    padding: 0 12px;
  }
}

.headerLogo {
  img {
    width: 100%;
    width: 160px;
  }
}

.headerBasketicons {
  display: flex;
  align-items: center;

  @media (min-width: $sm) {
    display: none;
  }
}

.iconBasket {
  flex-shrink: 0;

  img {
    padding-right: 16px;
  }
}

.headerDropDownMenu {
  flex-shrink: 0;
}

.headerCatalog {
  display: flex;
  padding-top: 16px;

  @media (min-width: $sm) {
    display: none;
    padding: 0 12px;
  }

  .dateOfDelivery {
    width: 100%;
    margin-right: 6px;
    justify-content: space-between;
    border-radius: 12px;

    span {
      white-space: nowrap;
      padding-left: 5px;
    }
  }
}

.searchIconMobile {
  background-color: red;
  padding: 10px;
}

.headerMenu {
  display: none;
  font-size: 14px;
  font-weight: 400;

  .headerMenuList {
    display: flex;

    li {
      margin-right: 24px;
    }

    li:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: $sm) {
    display: block;
  }

  .headerMenuItem {
    cursor: pointer;

    &:hover {
      color: $dark-bluishGreen;
    }
  }
}

.headerInfoBlock {
  box-sizing: border-box;
  display: flex;

  width: 100%;
}

.headerInfoBlockCatalog {
  width: 100%;

  @media (min-width: $sm) {
    display: flex;
    align-items: center;
  }
}

.headerInfoBlockSearchBlock {
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
}

.headerLogoDesktop {
  margin-right: 19px;
}

.headerSearchDesktop {
  width: 100%;

  box-sizing: border-box;
  margin-right: 16px;
  position: relative;
  border-radius: 52px;

  z-index: $search-desktop;

  .searchButton {
    padding: 0;
    position: absolute;
    right: 12px;
    top: 10px;

    img {
      max-width: 24px;
      width: 100%;
    }
  }

  input {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #e0e0e0;
    outline: none;
    max-height: 44px;
  }
}

.headerSearchDesktopActive {
  background-color: $white-transparent;
  border-radius: 12px 12px 0 0;
}

.searchModalWrapper {
  position: absolute;
  left: 0;
  right: 0;
  z-index: $z-100;
  border-radius: 0 0 12px 12px;
  overflow: auto;
  background-color: $white-transparent;
  max-height: 550px;
  padding-bottom: 16px;
}

.searchModalBlock {
  padding: 16px 16px 20px 16px;
}

.historyBlock {
}

.buttonText {
  color: $telegray;
}

.buttonClearHistory {
  padding: 0;
}

.historyButtonBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.historyMenu {
  margin-top: 20px;
}

.historyMenuItem {
  margin-bottom: 16px;
}

.headerInteractionIcons {
  display: flex;
  align-items: center;

  img {
    max-width: 24px;
    width: 100%;
  }

  .interactionIconsFavorite {
    margin-right: 16px;
    flex-shrink: 0;
  }

  .interactionUser {
    flex-shrink: 0;
  }

  @media (max-width: $sm) {
    display: none;
  }
}

.interactionIconsBasket {
  margin-right: 16px;
  flex-shrink: 0;
  position: relative;
}

.basketProductCount {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 11px;
  background-color: $dark-bluishGreen;
  top: -3px;
  left: -3px;
  color: $white;

  width: 14px;
  height: 14px;
  border-radius: 90px;
}

.headerDesktopTags {
  margin-top: 16px;
  display: none;

  @media (min-width: $sm) {
    display: block;
  }
}

.headerTagsMenuList {
  display: flex;
  font-size: 16px;
  align-items: center;

  .headerTagsMenuPromotion {
    margin-right: 16px;
    display: flex;
    align-items: center;
    color: $dark_yellow-green;

    img {
      margin-right: 4px;
    }
  }

  .headerTagsMenuPercent {
    display: flex;
    align-items: center;
    color: #f33e3c;

    img {
      margin-right: 4px;
    }

    .divider {
      margin: 0 16px;
    }
  }

  .headerTagsMenuItem {
    margin-right: 16px;
    padding: 0;
  }

  .logout {
    margin-left: auto;
    margin-right: 0;
  }
}

.additionalTagsBlock {
  margin-top: 12px;
  border-bottom: 1px solid $gainsborough;
  padding-bottom: 16px;
}

.additionalTagsMenuList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.additionalTagsMenuItem {
  font-size: 12px;
  background-color: $periwinkle-crayola;
  border-radius: 8px;
  padding: 8px 10px;
  margin: 0 8px 8px 0;
}

.subcategoriesWrapper {
  margin-top: 16px;
}

.subcategoriesBlock {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categoryTitle {
  margin-top: 2px;
  color: $white-aluminum;
}

.productWrapper {
  text-align: center;
  display: flex;
  position: relative;
  z-index: 1;
  margin: 16px -8px 0 -8px;
  overflow-x: auto;

  @media (max-width: 1024px) {
  }

  @media (max-width: $sm) {
    overflow-x: auto;
    margin: 24px -8px 0 -8px;
  }
}

.ProductCardIndents {
  padding: 0 8px;
  width: 55%;
  box-sizing: border-box;
  min-width: 268px;
  max-width: 268px;

  @media (max-width: 1024px) {
    min-width: 242px;
    margin: 0 5px;
    // max-width: 268px;
  }

  @media (max-width: $sm) {
    min-width: 160px;
  }
}

.navBusiness {
  margin-bottom: 16px;
}

.showMoreSearch {
  font-weight: 500;
  background-color: $saffron-yellow;
  padding: 14px 15px;
  border-radius: 12px;
  margin: 0 auto;
}

.headerCatalogText {
  @media (max-width: $sm) {
    display: none;
  }
}

.desktopSearch {
  width: 100%;
  flex-grow: 1;
  display: flex;
  position: relative;

  @media (max-width: $sm) {
    display: none;
  }
}

.mobileSearch {
  @media (min-width: $sm) {
    display: none;
  }
}

.businessContainer {
  padding-top: 16px !important;
}

.menuLink {
  display: flex;
  align-items: center;
}
