.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1192px;
  padding: 0 16px;
  margin: 0 auto;
}

.large {
  max-width: 1300px;
}