@import 'src/styles/colors_v2';
@import 'src/styles/colors';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: 0;
  border-radius: 8px;
  padding: 12px 8px;
  box-sizing: border-box;
  appearance: none;

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.7;
    background-color: $slate-gray;
    color: $white-aluminum;
    cursor: not-allowed;
  }
}

.dark-bluishGreen {
  background-color: $dark-bluishGreen;
  color: $white;
}
.white-transparent {
  background-color: $white-transparent;
}

.navigation-lg {
  padding: 26px 26px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: white;
  border: none;
  background-size: 9px;
  border-radius: 106px;
}

.navigation-sm {
  padding: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: white;
  border: none;
  background-size: 9px;
  border-radius: 106px;
}
.favorite-sm {
  background-color: transparent;
}

.primary {
  background: $yellow;
  color: $font-black;

  &:hover:not(:disabled) {
    background: $yellow-light;
  }

  &:focus {
    background: $yellow-pressed;
  }
}

.active {
  background: $dark-bluishGreen;
  color: $white;

  &:hover:not(:disabled) {
    background: lighten($dark-bluishGreen, 7);
  }

  &:focus {
    background: darken($dark-bluishGreen, 2);
  }
}

.info {
  background: $green-shift;
  color: $green;

  &:hover:not(:disabled) {
    background: darken($green-shift, 3);
  }

  &:focus {
    background: darken($green-shift, 7);
  }

  svg {
    fill: $green;
  }
}

.danger {
  background: rgba($red, 0.1);
  color: $red;

  &:hover:not(:disabled) {
    background: rgba($red, 0.2);
  }

  &:focus {
    background: rgba($red, 0.3);
  }

  svg {
    fill: $red;
  }
}

.transparent {
  background-color: transparent;

  &:hover:not(:disabled) {
    opacity: 0.8;
  }

  &:focus {
    cursor: pointer;
  }
}

.xs {
  height: 48px;
}
.sm {
  padding: 12.8px 0;
}
.md {
  padding: 12px 6px;
}

.lg {
  padding: 0 53px;
}

.icon {
  flex-shrink: 0;
}

.iconMd {
  width: 60px;
  height: 60px;
}

.iconLg {
  padding: 16.5px 16px;
}

.iconXs {
  padding: 10px;
}

.rounded {
  border-radius: 8px;
}

.fluid {
  width: 100%;
}

.circle {
  border-radius: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.increased {
  height: 60px;
}
