@import 'src/styles/colors_v2';

.container {
  :global {
    .async-select {
      &__control {
        min-height: 52px;
        border: 1px solid $white-transparent;
        border-radius: 8px;
        background: $white-transparent;
        box-shadow: none;
        padding-left: 15px;
      }

      &__control--is-focused,
      &__control--is-focused:hover {
        background: darken($white-transparent, 5%);
      }

      &__menu {
        // border: 1px solid $green-saturated;
        background: #f5f5f5;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: none;
      }

      &__input input {
        height: 28px;
      }

      span {
        border: 0;
      }

      &__placeholder,
      &__single-value {
        line-height: 1.5;
      }
    }
  }
}

.withError {
  :global {
    .async-select {
      &__control {
        border-color: $red;
      }
    }
  }
}

.option {
  display: flex;
  align-items: center;
  padding: 13px 15px;
  font-size: 15px;
  line-height: 14px;
  // color: $font-black;

  &:hover,
  &.focused {
    // background: $green-shift;
    cursor: pointer;
  }
}

.photo {
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 14px;

  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 30px;
  }
}

.status {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;

  &::before {
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    top: -7px;
    left: -7px;
    background: $white;
    border-radius: 100%;
    z-index: 1;
  }

  &::after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: -5px;
    left: -5px;
    // background: $green-saturated;
    border-radius: 100%;
    z-index: 2;
  }
}

.multiValueContainer {
  display: flex;
  flex-wrap: wrap;
}

.multiValue {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 15px;
  margin: 4px 2.5px;
  border-radius: 30px;
  // background: $green;
}

.multiValueLabel {
  font-size: 14px;
  line-height: 13px;
  color: $white;
}

.valueRemove {
  margin-left: 3px;

  svg {
    fill: $white;
    width: 10px;
    margin-left: 3px;
  }

  &:hover {
    background: transparent;
    cursor: pointer;
  }
}
