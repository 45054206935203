@import '../../../styles//colors_v2.scss';

.container {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  padding: 27px 12px 10px;
  background: $smoky-white3;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  appearance: textfield;

  &:-autofill,
  &:-autofill:hover,
  &:-autofill:focus,
  &:-autofill:active {
    -box-shadow: 0 0 0 30px $white inset !important;
  }
}

.inputLabelText {
  position: absolute;
  top: 19px;
  left: 13px;
  font-size: 14px;
  color: $telegray;
  transition:
    font-size 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  pointer-events: none;
}

.filledInputLabelText {
  font-size: 12px;
  transform: translateY(-11px);
}

.noLabel {
  padding: 17px 12px;
}