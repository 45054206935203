@import 'src/styles/colors';
@import 'src/styles/colors_v2';
@import 'src/styles/breakpoints';

.footer {
  color: $white;
  padding: 40px 0;
  background: $dark-bluishGreen;
  box-sizing: border-box;

  @media (min-width: $sm) {
    padding: 60px 70px 50px;
  }
}

.wrap {
}

.links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  border-bottom: 2px solid rgba($color: $white, $alpha: 0.2);
}

.linksInner {
  margin-right: 7%;
}

.instagram svg {
  fill: $green;
}

.InnerItem {
  margin: 30px 0;
  display: flex;

  &:first-child {
    margin-top: 0;
  }
}

.item {
  margin-top: 16px;
}

.copyrite {
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
}

.logoLink {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $green;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;

  svg {
    fill: $green;
    padding-right: 10px;
  }
}

.footerList,
.linksInner,
.distribution {
  width: 25%;
}

.socialLinks {
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  background: $white;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover svg {
    fill: $yellow;
  }
}

.socialIcon {
  fill: $green;
  max-width: 40px;
  height: auto;
}

.tiktok {
  svg {
    stroke: $green;
    stroke-width: 2;
  }

  &:hover svg {
    stroke: $yellow;
  }
}

.linkImg {
  padding: 12px;
}

.listTitle {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.listItem {
  margin: 21px 0;
}

.itemLink {
  min-height: 21px;
  font-size: 16px;
  line-height: 130%;
  text-decoration: none;
  color: $white;
  font-weight: 400;

  &:hover {
    border-bottom: 1px solid $green;
  }
}

.distribution {
  margin-left: 3%;
}

.distributionText {
  font-size: 20px;
  line-height: 150%;
  color: $black;
}

.info {
  padding: 42px 0 0;
  font-size: 14px;
  line-height: 150%;
  opacity: 0.8;
}

.line {
  width: 100%;
  height: 1px;
  background: $green;
  opacity: 0.1;
}

.moreBtn {
  display: none;
}

@media (max-width: 768px) {
  .links {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .linksInner {
    margin: 0;
    width: 100%;
  }

  .InnerItem {
    margin: 16px 0 34px 0;
  }

  .footerList {
    min-width: 200px;
    color: $white;
  }

  .info {
    padding-top: 30px;
    height: 126px;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
  }

  .infoActive {
    display: table;
  }

  .moreBtn {
    display: flex;
    color: $green;

    svg {
      fill: $green;
      margin-left: 7px;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.footerPolicyBlock {
  a {
    color: $white;
    opacity: 0.3;

    &:last-child {
      margin-left: 16px;
    }
  }
}
